export default [
  'Вчера',
  'Прошлая неделя',
  'Сегодня',
  'Эта неделя',
  'Прошлый месяц',
  'Прошлый год',
  'Этот месяц',
  'Этот год',
]
